import React, { Component } from 'react';
import logo from '../dokibo-profile-icon@512w.png';
import './App.css';

class HomePage extends Component {
  render() {
    return (
      <div className="App">
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Dokibo</h1>
          <h2>Chat with friends to learn Chinese</h2>
        </div>
        <p className="App-intro">
          <a className="google-play-badge" href='https://play.google.com/store/apps/details?id=com.dokibo.androidapp&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'/></a>
        </p>


        <img className="screenshot" src={process.env.PUBLIC_URL + '/Screenshot 1.png'} />
        <img className="screenshot" src={process.env.PUBLIC_URL + '/Screenshot 2.png'} />
        <br />
        <img className="screenshot" src={process.env.PUBLIC_URL + '/Screenshot 3.png'} />
        <img className="screenshot" src={process.env.PUBLIC_URL + '/Screenshot 4.png'} />
        <br />
        <img className="screenshot" src={process.env.PUBLIC_URL + '/Screenshot 5.png'} />


        <p>
          <a className="google-play-badge" href='https://play.google.com/store/apps/details?id=com.dokibo.androidapp&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'/></a>
        </p>

        <div className="Footer">
          Google Play and the Google Play logo are trademarks of Google Inc.
        </div>
      </div>
    );
  }
}

export default HomePage;
