import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom'

import HomePage from './pages/HomePage';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';
// import ReadPage from './pages/ReadPage';
// import ReadingLevelPage from './pages/ReadingLevelPage';
// import BookPrepPage from './pages/BookPrepPage';
// import DocParagraphsPage from './pages/DocParagraphsPage';
// import SignInPage from './pages/SignInPage';

// import firebase from 'firebase';

// import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// import red from '@material-ui/core/colors/red';
// import green from '@material-ui/core/colors/green';

// All the following keys are optional.
// We try our best to provide a great default value.
// const theme = createMuiTheme({
//   palette: {
//     primary: green,
//     secondary: green,
//     error: red,
//     // Used by `getContrastText()` to maximize the contrast between the background and
//     // the text.
//     contrastThreshold: 3,
//     // Used to shift a color's luminance by approximately
//     // two indexes within its tonal palette.
//     // E.g., shift from Red 500 to Red 300 or Red 700.
//     tonalOffset: 0.2,
//   },
//   typography: {
//     fontSize: 20,
//   }
// });

// console.log(theme);

class AppGlobal extends Component {
  state = {
    loaded: false,
  }

  componentWillMount() {
    this.deregisterFirebaseAuthListener = firebase.auth().onAuthStateChanged(this.setup.bind(this));
  }

  componentWillUnmount() {
    this.deregisterFirebaseAuthListener && this.deregisterFirebaseAuthListener();
  }

  setup(user) {
    this.setState({loaded: true});
  }

  render() {
    if (this.state.loaded) {
      return this.props.children;
    } else {
      return (
        <div></div>
      )
    }
  }
}

// class App extends Component {
//   render() {
//     return (
//       <MuiThemeProvider theme={theme}>
//         <AppGlobal>
//           <Router>
//             <Switch>
//               <Route exact path="/" component={HomePage}/>
//               <Route path="/d/:docId/p/:paragraphId" component={BookPrepPage}/>
//               <Route path="/d/:docId/p" component={DocParagraphsPage}/>
//               <Route path="/signin" component={SignInPage}/>
//             </Switch>
//           </Router>
//         </AppGlobal>
//       </MuiThemeProvider>
//     );
//   }
// }


class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/terms" element={<TermsPage />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
